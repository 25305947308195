import React from 'react'
import { ApolloProvider } from '@apollo/client'
import App from 'next/app'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { CookiesProvider } from 'react-cookie'
import ApiContext from '../utils/api-context'
import { appWithTranslation } from 'next-i18next'
import '../styles/globals.css'
import '../styles/react_dates_overrides.css'

import setupApolloClient from '../utils/apolloClient'
import { ModalProvider } from '../lib/context/modal-context'
import { SearchContextProvider } from '../lib/context/search-context'
import CartContextProvider from '../lib/context/cart-context'
import { CurrencyContextProvider } from '../lib/context/CurrencyContext'
import { SettingsContextProvider } from '../lib/context/SettingsContext'
import { UserContextProvider } from '../lib/context/UserContext'
import { MobileContextProvider } from '../lib/context/mobile-context'
import Meta from '../components/meta'

function WellnessTraveller({ Component, pageProps }) {
  const cookie = require('cookie')
  var cookies = cookie.parse(pageProps.cookie || '')
  const client = setupApolloClient(
    cookies.hasOwnProperty('jwt') ? cookies.jwt : ''
  )

  return (
    <>
      {pageProps.errorCode === 200 &&
        pageProps.data &&
        Array.isArray(pageProps.data) && (
          <Meta {...pageProps.data[0].seoMetaData} />
        )}
      {pageProps.errorCode === 200 &&
        pageProps.data &&
        !Array.isArray(pageProps.data) &&
        !pageProps.hasOwnProperty('seoMetaData') && (
          <Meta {...pageProps.data} />
        )}
      {pageProps.errorCode === 200 &&
        pageProps.data &&
        !Array.isArray(pageProps.data) &&
        pageProps.hasOwnProperty('seoMetaData') && (
          <Meta {...pageProps.data.seoMetaData} />
        )}
      <CookiesProvider>
        <SearchContextProvider>
          <ApolloProvider client={client}>
            <ApiContext.Provider
              value={{
                apiUrl: process.env.NEXT_PUBLIC_API_URL,
                cdnUrl: process.env.NEXT_PUBLIC_CDN_URL,
              }}
            >
              <MobileContextProvider>
                <CartContextProvider>
                  <UserContextProvider>
                    <CurrencyContextProvider>
                      <SettingsContextProvider>
                        <ModalProvider>
                          <Component {...pageProps} />
                        </ModalProvider>
                      </SettingsContextProvider>
                    </CurrencyContextProvider>
                  </UserContextProvider>
                </CartContextProvider>
              </MobileContextProvider>
            </ApiContext.Provider>
          </ApolloProvider>
        </SearchContextProvider>
      </CookiesProvider>
    </>
  )
}
WellnessTraveller.getServerSideProps = async (appContext) => {
  return {
    ...(await App.getInitialProps(appContext)),
  }
}
export default appWithTranslation(WellnessTraveller)
