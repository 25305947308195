import React, { ReactNode, useEffect, useState } from 'react'
import { Cookies } from 'react-cookie'
import { CookieSetOptions } from 'universal-cookie'
import { SearchContextParams } from '../../interface'
import moment from 'moment'
import config from '../../config'

export const SearchContext = React.createContext<SearchContextParams>({
  searchParams: {
    checkinDate: moment().add(1, 'day'),
    checkoutDate: moment().add(2, 'days'),
    guests: [
      {
        paxes: [{ age: 30 }, { age: 30 }],
      },
    ],
    filterType: [
      {
        name: '',
        id: 0,
      },
    ],
    filterDuration: [
      {
        name: '',
        id: 0,
      },
    ],
    filterArea: [
      {
        name: '',
        id: 0,
      },
    ],
    query: '',
  },
  setSearchParameters: (data, exp, options) => {},
})

const cookies = new Cookies()
const storage = cookies.get('searchparams')
  ? cookies.get('searchparams')
  : {
      checkinDate: moment().add(1, 'day').format('YYYY-MM-DD'),
      checkoutDate: moment().add(2, 'days').format('YYYY-MM-DD'),
      guests: [
        {
          paxes: [{ age: 30 }, { age: 30 }],
        },
      ],
      filterType: [],
      filterDuration: [],
      filterArea: [],
      query: '',
    }

// @ts-ignore
export const SearchContextProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useState(storage)
  useEffect(() => {
    cookies.set('searchparams', searchParams, config.cookieOptions)
  }, [searchParams])
  const setSearchParameters = (
    data: any,
    exp: any,
    options: CookieSetOptions | undefined
  ) => {
    if (data.checkinDate === null) {
      data.checkinDate = moment().add(1, 'day').format('YYYY-MM-DD')
    }
    if (data.checkoutDate === null) {
      data.checkoutDate = moment().add(2, 'days').format('YYYY-MM-DD')
    }
    cookies.set('searchparams', data, options)
    setSearchParams(data)
  }
  return (
    <SearchContext.Provider
      value={{
        searchParams,
        setSearchParameters,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
