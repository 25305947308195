import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'
import config from '../config'
import { setContext } from '@apollo/client/link/context'

const setupApolloClient = (jwt) => {
  const httpLinkConfig = {
    uri: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
  }
  const cache = new InMemoryCache()
  const link = new HttpLink(httpLinkConfig)
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // const token = jwt !== '' ? jwt : process.env.JWT_TOKEN
    let token = {}
    if (jwt) {
      token.Authorization = `Bearer ${jwt}`
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        ...token,
      },
    }
  })
  return new ApolloClient({
    cache,
    link: authLink.concat(link),
  })
}

export default setupApolloClient
