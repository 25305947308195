import React, { useCallback, useEffect, useRef, useState } from 'react'

const ModalContext = React.createContext(null)

// @ts-ignore
const Modal = ({ modal, unSetModal }) => {
  const modalEl = useRef<HTMLDivElement>(null)
  const [ delay, setDelay ] = useState(modal.delay ? modal.delay : 0)
  useEffect(() => {
    const overlayClick = (evt) => {
      if (!modal.noClose && evt.target.classList.contains('modal-dialog')) {
        unSetModal()
      }
    }
    const bind = (e: { keyCode: number }) => {
      if (e.keyCode !== 27) {
        return
      }
      if (
        document.activeElement &&
        ['INPUT', 'SELECT'].includes(document.activeElement.tagName)
      ) {
        return
      }
      unSetModal()
    }

    if (modal.delay) {
      setTimeout(() => {
        setDelay(0)
      }, modal.delay * 1000)
    }

    document.addEventListener('keyup', bind)
    // modalEl.current.addEventListener('click', overlayClick)
    return () => {
      if (document) {
        document.removeEventListener('keyup', bind)
      }

      // if (modalEl && modalEl.current) {
      //   modalEl.current.removeEventListener('click',overlayClick)
      // }
    }
  }, [modal, unSetModal])

  return (
    <>
      {delay === 0 && (
        <div className="modal modal-dialog" ref={modalEl}>
          <div
            className="modalInner"
            style={{ width: modal.hasOwnProperty('width') ? modal.width : '90%' }}
          >
            <div className="modalHeader">
              <h2>{modal.title}</h2>
              {!modal.noClose && (
                <button className="popupClose" onClick={unSetModal} />
              )}
            </div>
            <div className="modalContent">{modal.content}</div>
          </div>
        </div>
      )}
    </>
  )
}

// @ts-ignore
const ModalProvider = (props) => {
  const [modal, setModal] = useState()
  const unSetModal = useCallback(() => {
    // @ts-ignore
    setModal()
  }, [setModal])

  return (
    <ModalContext.Provider value={{ unSetModal, setModal }} {...props}>
      {props.children}
      {modal && <Modal modal={modal} unSetModal={unSetModal} />}
    </ModalContext.Provider>
  )
}

const useModal = () => {
  const context = React.useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a UserProvider')
  }

  return context
}

export { ModalProvider, useModal }
