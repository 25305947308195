import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { Cookies } from 'react-cookie'
import { CartReducer } from './cart-reducer'
import ApiContext from '../../utils/api-context'
import config from '../../config'

export const CartContext = createContext(null)

const cookies = new Cookies()
const cartId = cookies.get('cartId') ? cookies.get('cartId') : 0
const initialBasket = {
  id: cartId,
  cartItems: [],
  checkout: false,
  customer: 0,
  itemCount: 0,
  showBasket: false,
}
const CartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CartReducer, initialBasket)
  const { apiUrl, cdnUrl } = useContext(ApiContext)

  const getCart = async (cartId) => {
    const customer =
      window && window.localStorage
        ? JSON.parse(window.localStorage.getItem('user'))
        : null

    const headerObj = {}

    if (customer && cookies.get('jwt')) {
      // @ts-ignore
      headerObj.Authorization = `Bearer ${cookies.get('jwt')}`
    }
    if (cartId > 0) {
      const response = await fetch(`${apiUrl}/carts/${cartId}`, {
        method: 'GET',
        headers: headerObj,
      })
      if (response.status === 404) {
        cookies.remove('cartId', config.cookieOptions)
        dispatch({ type: 'DELETE', state })
      } else {
        const cart = await response.json()
        const cartItems = cart.cart_items
        saveCartId(cart.id)
        dispatch({
          type: 'REFRESH',
          newCart: {
            id: cart.id,
            cartItems,
            checkout: false,
            customer: cart.user.id,
          },
        })
        return cartItems[cartItems.length - 1]
      }
      return []
    } else {
      if (customer) {
        const response = await fetch(`${apiUrl}/carts?user=${customer.id}`, {
          method: 'GET',
          headers: headerObj,
        })
        const cart = await response.json()
        if (cart.length > 0) {
          saveCartId(cart[0].id)
          dispatch({
            type: 'REFRESH',
            newCart: {
              id: cart[0].id,
              cartItems: cart[0].cart_items,
              checkout: false,
              customer: cart[0].user.id,
            },
          })
          return cart[0].cart_items[cart[0].cart_items.length - 1]
        }
      }
    }
  }

  useEffect(() => {
    getCart(state.id)
  }, [state.id])

  const addProduct = async (cartItem) => {
    let tmpCart
    if (state.id === 0) {
      const newCart = await createCart(cartItem)
      tmpCart = {
        ...cartItem,
        itemIds: newCart.itemIds,
      }
      getCart(newCart.id)
    } else {
      tmpCart = await updateCart(cartItem)
      getCart(state.id)
    }
    return tmpCart
  }
  const removeProduct = async (cartItem) => {
    await deleteCartItem(cartItem.id)
    dispatch({
      type: 'REMOVE',
      removeId: cartItem.id,
    })
  }
  const removeCart = async () => {
    await deleteCart(cookies.get('cartId'))
    cookies.remove('cartId', config.cookieOptions)
    dispatch({ type: 'DELETE', state })
  }
  const hideBasket = () => {
    dispatch({ type: 'HIDE', state })
  }
  const saveCartId = (cartId) => {
    cookies.set('cartId', cartId, config.cookieOptions)
  }
  const createCart = async (cartItem) => {
    const customer =
      window && window.localStorage
        ? JSON.parse(window.localStorage.getItem('user'))
        : null

    const headerObj = {}

    if (customer && cookies.get('jwt')) {
      // @ts-ignore
      headerObj.Authorization = `Bearer ${cookies.get('jwt')}`
    }

    const response = await fetch(`${apiUrl}/carts/login`, {
      method: 'POST',
      headers: headerObj,
      body: JSON.stringify({
        customer: customer ? customer.id : 0,
        cartItems: [cartItem],
      }),
    })
    return await response.json()
  }

  const updateCart = async (cartItem) => {
    const customer =
      window && window.localStorage
        ? JSON.parse(window.localStorage.getItem('user'))
        : null
    if (customer) {
      const response = await fetch(`${apiUrl}/carts/basket`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${cookies.get('jwt')}`,
        },
        body: JSON.stringify({
          id: state.id,
          customer: parseInt(customer.id),
          cartItem,
        }),
      })
      const cart = await response.json()
      if (!cartItem.itemIds) {
        cartItem.itemIds = cart.itemIds
      }
    }
    return cartItem
  }

  const deleteCart = async (cartId) => {
    const customer =
      window && window.localStorage
        ? JSON.parse(window.localStorage.getItem('user'))
        : null
    const headerObj = {}

    if (customer && cookies.get('jwt')) {
      // @ts-ignore
      headerObj.Authorization = `Bearer ${cookies.get('jwt')}`
    }
    if (cartId > 0) {
      const response = await fetch(`${apiUrl}/carts/${cartId}`, {
        method: 'DELETE',
        headers: headerObj,
      })
      return response.json()
    }
    return 'deleted'
  }

  const deleteCartItem = async (cartItemId) => {
    const customer =
      window && window.localStorage
        ? JSON.parse(window.localStorage.getItem('user'))
        : null

    const headerObj = {}

    if (customer && cookies.get('jwt')) {
      // @ts-ignore
      headerObj.Authorization = `Bearer ${cookies.get('jwt')}`
    }
    if (cartItemId > 0) {
      const response = await fetch(`${apiUrl}/cart-items/${cartItemId}`, {
        method: 'DELETE',
        headers: headerObj,
      })
      return response.json()
    }
    return 'deleted'
  }
  const contextObject = {
    addProduct,
    removeProduct,
    hideBasket,
    createCart,
    getCart,
    deleteCartItem,
    updateCart,
    deleteCart,
    removeCart,
    ...state,
    itemCount: state.cartItems.length,
  }
  return (
    <CartContext.Provider value={contextObject}>
      {children}
    </CartContext.Provider>
  )
}
export default CartContextProvider
