import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie'
export const UserContext = React.createContext({
  user: null,
  setCurrentUser: null,
  logoutCurrentUser: null,
})

const defaultUser = {
  id: null,
  email: null,
  profileImage: null,
  firstname: null,
  lastname: null,
  favourites: null,
  address: {
    telephone: null,
    street: null,
    region: null,
    country: null,
    postcode: null,
  },
  interests: null,
}
const cookies = new Cookies()
const storage =
  typeof window !== 'undefined' &&
  window.localStorage &&
  window.localStorage.getItem('user')
    ? JSON.parse(window.localStorage.getItem('user'))
    : defaultUser

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(storage)
  const setCurrentUser = (userData, exp = 0, options = {}) => {
    if (window && window.localStorage) {
      localStorage.setItem('user', JSON.stringify(userData))
    }
    setUser(userData)
  }
  useEffect(() => {
    if (window && window.localStorage) {
      const userObj = window.localStorage.getItem('user')
      if (userObj) {
        setUser(JSON.parse(userObj))
      }
    }
  }, [])
  const logoutCurrentUser = () => {
    cookies.remove('jwt', { path: '/' })
    if (window && window.localStorage) {
      window.localStorage.removeItem('user')
    }
    // cookies.remove('user')
    cookies.remove('cartId', { path: '/' })
    setUser(defaultUser)
  }
  return (
    <UserContext.Provider
      value={{
        user,
        setCurrentUser,
        logoutCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
