import React, { useContext } from 'react'
import Head from 'next/head'
import ApiContext from '../utils/api-context'
type Props = {
  title?: string
  keywords?: string
  description?: string
  image?: string
  socialMeta?: any
  structuredData?: any
  canonicalUrl?: string
}
const Meta = ({ title, keywords, description, image, socialMeta, structuredData, canonicalUrl }: Props) => {
  return (
    <Head>
      <title>{title}</title>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#000000"
      />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#00243F" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta name="theme-color" content="#00243F" />
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {structuredData && <script type="application/ld+json">{JSON.stringify(structuredData, null, "\t")}</script>}
      {socialMeta &&
        socialMeta.length > 0 &&
        socialMeta.map((meta, i) => {
          if (meta.socialNetwork === 'Facebook') {
            return (
              <React.Fragment key={i}>
                <meta property="og:title" content={meta.title} />
                <meta property="og:description" content={meta.description} />
                <meta
                  property="og:image"
                  content={`${
                    meta.image
                      ? meta.image.url
                      : 'https://cdn.wellnesstraveller.co/Facebook_Banner_89b8d02680.jpg'
                  }`}
                />
                <meta
                  property="og:image:alt"
                  content={meta.image.alternativeText}
                />
              </React.Fragment>
            )
          } else if (meta.socialNetwork === 'Twitter') {
            return (
              <React.Fragment key={i}>
                <meta property="twitter:title" content={meta.title} />
                <meta
                  property="twitter:description"
                  content={meta.description}
                />
                <meta
                  property="twitter:image"
                  content={`${
                    meta.image
                      ? meta.image.url
                      : 'https://cdn.wellnesstraveller.co/Twitter_Banner_01734c6436.jpg'
                  }`}
                />
                <meta
                  property="twitter:image:alt"
                  content={meta.image.alternativeText}
                />
              </React.Fragment>
            )
          }
        })}
      {(!socialMeta || socialMeta.length === 0) && (
        <>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta
            property="og:image"
            content={`https://cdn.wellnesstraveller.co/Facebook_Banner_89b8d02680.jpg`}
          />
          <meta property="og:image:alt" content={'Wellness Traveller'} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
          <meta
            property="twitter:image"
            content={`https://cdn.wellnesstraveller.co/Twitter_Banner_01734c6436.jpg`}
          />
          <meta property="twitter:image:alt" content={'Wellness Traveller'} />
        </>
      )}
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
    </Head>
  )
}
export default Meta
