import React, { ReactNode, useEffect, useState } from 'react'

export const MobileContext = React.createContext(false)

type Props = {
  children: ReactNode
}
export const MobileContextProvider = ({ children }: Props) => {
  const [mobile, setMobile] = useState<boolean>(false)
  useEffect(() => {
    const isMobile = () => {
      setMobile(window.innerWidth <= 981)
    }
    if (window) {
      isMobile()
      window.addEventListener('resize', isMobile)
      window.addEventListener('orientationchange', isMobile)
    }
    return () => {
      if (window) {
        window.removeEventListener('resize', isMobile)
        window.removeEventListener('orientationchange', isMobile)
      }
    }
  }, [mobile])
  return (
    <MobileContext.Provider value={mobile}>{children}</MobileContext.Provider>
  )
}
