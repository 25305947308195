import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie'
import axios from 'axios'
import config from '../../config'
export const CurrencyContext = React.createContext({
  currency: null,
  setCurrentCurrency: null,
})

const cookies = new Cookies()
const storage = cookies.get('currency') ? cookies.get('currency') : 'EUR'

export const CurrencyContextProvider = ({ children }) => {
  const [currency, setCurrency] = useState(storage)
  const setCurrentCurrency = (settingData, exp, options) => {
    cookies.set('currency', settingData, {
      ...options,
      expires: 0,
    })
    setCurrency(settingData)
  }
  useEffect(() => {
    if (!cookies.get('currency')) {
      const getUserCountryCode = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        return res.data.country_code === 'GB' ? 'GBP' : 'EUR'
      }
      getUserCountryCode().then((resp) => {
        setCurrentCurrency(resp, 0, config.cookieOptions)
      })
    }
  }, [currency])
  return (
    <CurrencyContext.Provider
      value={{
        currency,
        setCurrentCurrency,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  )
}
