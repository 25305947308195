import React, { useEffect, useState } from 'react'
import axios from 'axios'

export const SettingsContext = React.createContext({
  settings: null,
  navigation: null,
})

export const SettingsContextProvider = ({ children }) => {
  const [settings, setSettings] = useState(null)
  const [navigation, setNavigation] = useState(null)
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_API_URL}/website-settings`,
    }).then((r) => setSettings(r.data))
    axios({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_API_URL}/navigation-menus`,
    }).then((r) => setNavigation(r.data))
  }, [])
  return (
    <>
      {settings && navigation && (
        <SettingsContext.Provider value={{ settings, navigation }}>
          {children}
        </SettingsContext.Provider>
      )}
    </>
  )
}
