export const CartReducer = (state, action) => {
  let newBasket, cartItems
  switch (action.type) {
    case 'ADD':
      cartItems = state.cartItems
      cartItems.push(action.item)
      newBasket = {
        ...state,
        cartItems,
        itemCount: cartItems.length,
        showBasket: true,
      }
      break
    case 'REMOVE':
      cartItems = state.cartItems.filter(
        (item, i) => item.id !== action.removeId
      )
      newBasket = {
        ...state,
        cartItems,
        itemCount: cartItems.length,
        showBasket: false,
      }
      break
    case 'CLEAR':
      newBasket = {
        ...state,
        cartItems: [],
        itemCount: 0,
        showBasket: false,
      }
      break
    case 'DELETE':
      newBasket = {
        ...state,
        cartItems: [],
        itemCount: 0,
        id: 0,
        showBasket: false,
      }
      break
    case 'HIDE':
      newBasket = {
        ...state,
        showBasket: false,
      }
      break
    case 'REFRESH':
      newBasket = {
        ...state,
        ...action.newCart,
        showBasket: true,
      }
      break
    default:
      throw new Error('INVALID CART ACTION!')
  }
  return newBasket
}
