export const defaultColours = ['#7BCAD2', '#F2E5D2', '#EC6C68']

const config = {
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  travelgateApiKey: 'eaf637e2-bcff-4f2a-69dc-ab1f05a15d66',
  travelgateClient: 'thewellnesstraveller',
  accessCode: '8973',
  cookieOptions: {
    sameSite: true,
    path: '/',
    httpOnly: false,
    secure: process.env.NODE_ENV === 'production',
  },
}
export default config
